export default class InitalLoader{
	constructor(){
		if(document.body.classList.contains("index")){
			this.indexInit();
		}else{
			this.generalInit();
		}
	}
	indexInit(){
		// index.jsでスライドショーと合わせて初期ロードを設定
	}
	generalInit(){
		if(!document.documentElement.classList.contains("wf-active")){
			let classObserver = new MutationObserver(records => {
				if(document.documentElement.classList.contains("wf-active")){
					classObserver.disconnect();
					classObserver = null;
					document.documentElement.classList.add("ready");
				}
			});
			classObserver.observe(document.documentElement, {
				attributes: true,
				attributeFilter: ['class']
			});
		}else{
			document.documentElement.classList.add("ready");
		}
		
	}
}