import anime from 'animejs/lib/anime.es.js';

export default class Slideshow{
	constructor(){
		this.init();
	}

	init(){
		this.slideshow = document.querySelectorAll('.slideshow');
		this.slideshow.forEach((elm)=>{
			this.initialize(elm);
		});
	}

	initialize(elm){
		const slideshow = elm;
		const viewport = slideshow.querySelector('.slideshow__viewport');
		const container = slideshow.querySelector('.slideshow__container');
		const pager = slideshow.querySelector('.slideshow__pager');
		const pages = slideshow.querySelectorAll('.slideshow__pager__page');
		const images = slideshow.querySelectorAll('.slideshow__image');
		const num = images.length;
		let position = 0;
		let isMoving = false;

		/* (vp2) (vp1) [img0] [img1] [img2] ... (vn1) (vn2) */
		let tmparray = Array.from(images);
		const vp1 = tmparray.pop().cloneNode(true);
		tmparray.unshift(vp1);
		const vp2 = tmparray.pop().cloneNode(true);
		tmparray = Array.from(images);
		const vn1 = tmparray.shift().cloneNode(true);
		tmparray.push(vn1);
		const vn2 = tmparray.shift().cloneNode(true);

		const start = window.isTouch ? "touchstart" : "mousedown";
		const move = window.isTouch ? "touchmove" : "mousemove";
		const end = window.isTouch ? "touchend" : "mouseup";
		const leave = window.isTouch ? "touchleave" : "mouseleave";
		let isDragging = null;
		let startX,startY,moveX,moveY = 0;

		const autoSlideDuration = 10000;
		let autoSlideTimer = null;

		function mouseStartHandler(e){
			e.stopPropagation();
			isDragging = false;
			startX = e.pageX;
			startY = e.pageY;
		}
		function mouseMoveHandler(e){
			if(isDragging === null) return false;
			if(isDragging === true) e.preventDefault();
			moveX = e.pageX;
			moveY = e.pageY;
			if(isDragging === false){
				if(window.isTouch){
					if(Math.abs(moveX - startX) > Math.abs(moveY - startY)){
						isDragging = true;
						stopAutoSlide();
					}else{
						isDragging = null;
					}
				}else{
					isDragging = true;
				}
			}
			if(isDragging){
				container.style.transform = "translateX(calc("+(-100*position)+"% + "+(-1*position + (moveX - startX)/20)+"rem))";
			}
		}
		function mouseEndHandler(e){
			if(isDragging === true) {
				if((moveX - startX) > 0){
					prev();
				}else{
					next();
				}
			}
			isDragging = null;
		}

		function jump(p){
			if(isMoving) return false;
			position = p;
			slide();
		}
		function next(){
			if(isMoving) return false;
			position = position+1;
			slide();
		}
		function prev(){
			if(isMoving) return false;
			position = position-1;
			slide();
		}
		function slide(){
			isMoving = true;
			slideshow.classList.add("moving");
			let pagerPosition = position;
			if(position >= num){
				pagerPosition = 0;
			}else if(position <= -1){
				pagerPosition = num-1;
			}
			slideshow.setAttribute("data-position", pagerPosition);
			anime({
				targets: container,
				translateX: "calc("+(-100*position)+"% + "+(-1*position)+"rem)",
				duration: 500,
				easing: 'easeOutExpo',
				complete: function(anim){
					isMoving = false;
					slideshow.classList.remove("moving");
					if(position >= num){
						position = 0;
					}else if(position <= -1){
						position = num-1;
					}
					container.style.transform = "translateX(calc("+(-100*position)+"% + "+(-1*position)+"rem))";
				}
			});
			startAutoSlide();
		}

		function stopAutoSlide(){
			if(autoSlideTimer){
				clearTimeout(autoSlideTimer);
				autoSlideTimer = null;
			}
		}
		function startAutoSlide(){
			stopAutoSlide();
			autoSlideTimer = setTimeout(next, autoSlideDuration);
		}

		pages.forEach(p=>{
			p.addEventListener("click", ()=>{
				jump(p.getAttribute("data-page")*1);
			}, false);
		})

		vp1.classList.add('pseudoslide', 'prev1');
		vp2.classList.add('pseudoslide', 'prev2');
		vn1.classList.add('pseudoslide', 'next1');
		vn2.classList.add('pseudoslide', 'next2');
		container.appendChild(vn1);
		container.appendChild(vn2);
		container.insertBefore(vp1, container.firstChild);
		container.insertBefore(vp2, container.firstChild);

		viewport.addEventListener(start, mouseStartHandler, false);
		viewport.addEventListener(move, mouseMoveHandler, false);
		viewport.addEventListener(end, mouseEndHandler, false);
		viewport.addEventListener(leave, mouseEndHandler, false);

		startAutoSlide();
		slideshow.setAttribute("data-position", position);
	}
}
