import imagesLoaded from 'imagesloaded';
export default class Index{
	constructor(){
		if(document.body.classList.contains("index")){
			this.initialize();
		}
	}
	initialize(){
		const threshold = 30;
		let flag = false;

		function update(){
			if(!flag){
				if(window.scrollY > threshold){
					flag = true;
					document.body.classList.add("show");
				}
			}else{
				if(window.scrollY <= threshold){
					flag = false;
					document.body.classList.remove("show");
				}
			}
			requestAnimationFrame(update);
		}

		requestAnimationFrame(update);


		const fv = document.querySelector(".index__bg");
		const fvImages = document.querySelectorAll(".index__bg__media");
		const timerDuration = 10000;
		let timer = null;
		let position = 0;
		function updateFvSlideshow(){
			if(timer) clearTimeout(timer);
			const current = document.querySelector(".index__bg__media[data-num='"+position+"']");
			if(current.classList.contains("video")){
				const video = current.querySelector("video");
				video.play();
			}
			fv.setAttribute("data-position",position);
			setTimeout(()=>{
				position = (position+1 >= fvImages.length) ? 0 : position+1;
				updateFvSlideshow();
			}, timerDuration);
		}


		let isFontReady = false;
		let isImageReady = false;
		let firstmedia = document.querySelector(".index__bg__media[data-num='0']");
		function progress(){
			if(isFontReady && isImageReady){
				document.documentElement.classList.add("ready");
				updateFvSlideshow();
			}
		}
		if(firstmedia.classList.contains("video")){
			const video = firstmedia.querySelector("video");
			video.addEventListener("canplaythrough", ()=>{
				firstmedia.classList.add("ready");
				isImageReady = true;
				progress();
			}, {once: true});
		}else{
			const img = firstmedia.querySelector("img");
			imagesLoaded(img, ()=>{
				firstmedia.classList.add("ready");
				isImageReady = true;
				progress();
			});
		}
		if(!document.documentElement.classList.contains("wf-active")){
			let classObserver = new MutationObserver(records => {
				if(document.documentElement.classList.contains("wf-active")){
					isFontReady = true;
					classObserver.disconnect();
					classObserver = null;
					progress();
				}
			});
			classObserver.observe(document.documentElement, {
				attributes: true,
				attributeFilter: ['class']
			});
		}else{
			isFontReady = true;
			progress();
		}
	}
}