export default class FloatingThumb{
	constructor(){
		const thumbFrame = document.querySelector(".works__bgthumb");
		if(thumbFrame) this.initialize(thumbFrame);
	}
	initialize(thumbFrame){
		if(isTouch) return;
		if(!thumbFrame) return;
		
		const targets = document.querySelectorAll("*[data-floatingthumb]");
		let animID = null;
		let targetX = 0;
		let targetY = 0;
		let posX = 0;
		let posY = 0;
		let isHover = false;

		const updatePosition = ()=>{
			if(isHover){
				const deltaX = (targetX - posX)/10;
				const deltaY = (targetY - posY)/10;
				posX = posX + deltaX;
				posY = posY + deltaY;
				if(Math.abs(deltaX) > 0.1 || Math.abs(deltaY) > 0.1){
					animID = requestAnimationFrame(updatePosition);
				}
			}else{
				posX = targetX;
				posY = targetY;
			}
			thumbFrame.style.transform = "translate3d("+posX+"px, "+posY+"px, 0px)";
		};
		const move = (e)=>{
			if(e.changedTouches){
				targetX = e.changedTouches[0].clientX;
				targetY = e.changedTouches[0].clientY;
			}else{
				targetX = e.clientX;
				targetY = e.clientY;
			}
			if(animID){ cancelAnimationFrame(animID); }
			animID = requestAnimationFrame(updatePosition);
		}
		const enter = (e,id,target)=>{ 
			const thumb = thumbFrame.querySelector("[data-floatingthumb-name='"+id+"']");
			thumb.classList.add("show");
			target.classList.add("hover");
			isHover = true;
		}
		const leave = (e,id,target)=>{ 
			const thumbs = thumbFrame.querySelectorAll(".show");
			thumbs.forEach(thumb=>thumb.classList.remove("show"));
			target.classList.remove("hover");
			isHover = false;
		}

		targets.forEach((target,index)=>{
			const imgpath = target.dataset.floatingthumb;
			const direction = (target.dataset.floatingthumbDirection=="horizontal") ? "horizontal" : "vertical";
			const img = document.createElement("img");
			const div = document.createElement("div");
			const id =  "thumb-"+index;
			target.setAttribute("data-floatingthumb-id", id);
			img.src = imgpath;
			div.append(img);
			div.classList.add(direction);
			div.setAttribute("data-floatingthumb-name", "thumb-"+index);
			thumbFrame.append(div);

			target.addEventListener("mouseenter", (e)=>{enter(e,id,target)}, false);
			target.addEventListener("mouseleave", (e)=>{leave(e,id,target)}, false);
		});
		document.body.addEventListener("mousemove", move, false);
	}
}