import 'nodelist-foreach-polyfill';
import Slideshow from './modules/_Slideshow.js';
import FloatingThumb from './modules/_floatingthumb.js';
import InitalLoader from './modules/_initialloader.js';
import Header from './modules/_header.js';
import Index from './modules/_index.js';
import MailForm from './modules/_mailform.js';

class App {
	constructor() {
		this.Slideshow = new Slideshow();
		this.FloatingThumb = new FloatingThumb();
		this.InitalLoader = new InitalLoader();
		this.Header = new Header();
		this.Index = new Index();
		this.MailForm = new MailForm();
	}
}

document.addEventListener('DOMContentLoaded', () => {
	window.isTouch = false;
	if( navigator.userAgent.indexOf('iPhone') > 0        ||
		navigator.userAgent.indexOf('iPod') > 0          ||
		navigator.userAgent.indexOf('iPad') > 0          ||
		navigator.userAgent.indexOf('Windows Phone') > 0 ||
		navigator.userAgent.indexOf('Android') > 0 ) {
		window.isTouch = true;
		document.body.classList.add('touch');
	}

	const resizeHandler = ()=>{
		const vw = document.body.clientWidth;
		document.documentElement.style.setProperty('--vw', vw+'px');
	}
	window.addEventListener("resize", resizeHandler, false);
	resizeHandler();
	
	let app = new App();
}, false);